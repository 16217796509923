import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/NavBar.css';
import navlogo from '../assets/images/navlogo.png'

const NavBar = ({ refs, activeSection }) => {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/');
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }, 0);
  };

  const scrollToSection = (sectionRef) => {
    navigate('/');
    setTimeout(() => {
      if (sectionRef && sectionRef.current) {
        sectionRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0);
  };

  return (
    <header>
      <div className='logo' onClick={handleLogoClick}>
        <img src={navlogo} alt="IMAGINERY.logo" />
      </div>
      <div className='menu-container'>
        <div className={`menu ${activeSection === 'robobrush' ? 'active' : ''}`} onClick={() => scrollToSection(refs.robobrushRef)}>ROBOBRUSH</div>
        <div className={`menu ${activeSection === 'echoes' ? 'active' : ''}`} onClick={() => scrollToSection(refs.echoesRef)}>ECHOES</div>
        <div className={`menu ${activeSection === 'datavoucher' ? 'active' : ''}`} onClick={() => scrollToSection(refs.datavoucherRef)}>DATA-VOUCHER</div>
      </div>
    </header>
  );
};

export default NavBar;
