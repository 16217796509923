import React, { useEffect, useRef, useState } from 'react';
import Robobrush from '../components/Robobrush';
import Echoes from '../components/Echoes';
import DataVoucher from '../components/DataVoucher';
import NavBar from '../components/NavBar';
import '../styles/Main.css'
import logobox from '../assets/images/logobox.svg'
import logoitem from '../assets/images/logoitem.svg'

const Main = () => {
  const [activeSection, setActiveSection] = useState(null);
  const [userInactive, setUserInactive] = useState(true);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [autoScrollEnabled, setAutoScrollEnabled] = useState(true);

  const robobrushRef = useRef(null);
  const echoesRef = useRef(null);
  const datavoucherRef = useRef(null);
  const topPage = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      let nearestSection = null;
      let nearestDistance = Infinity;
  
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const targetCenterY = entry.boundingClientRect.top + entry.boundingClientRect.height / 2;
          const windowCenterY = window.innerHeight / 2;
          const distanceToCenter = Math.abs(targetCenterY - windowCenterY);
  
          if (distanceToCenter < nearestDistance) {
            nearestDistance = distanceToCenter;
            nearestSection = entry.target;
          }
        }
      });

      // 'topPage' 가 보이면 모든 섹션의 'active' 제거
      if (nearestSection === topPage.current) {
        setActiveSection(null);
      } else if (nearestSection === robobrushRef.current) {
        setActiveSection('robobrush');
      } else if (nearestSection === echoesRef.current) {
        setActiveSection('echoes');
      } else if (nearestSection === datavoucherRef.current) {
        setActiveSection('datavoucher');
      }
    }, { threshold: 0.5 });
  
    observer.observe(robobrushRef.current);
    observer.observe(echoesRef.current);
    observer.observe(datavoucherRef.current);
    observer.observe(topPage.current);
  
    return () => {
      observer.disconnect();
    };
  }, []);

  // 사용자 상호작용 감지를 위한 함수
  const handleUserActivity = () => {
    setUserInactive(false);
    clearTimeout(userActivityTimeout);
    userActivityTimeout = setTimeout(() => {
      setUserInactive(true);
    }, 500); // .5초 동안 사용자 상호작용이 없으면 userInactive를 true로 설정
  };

  let userActivityTimeout;

  useEffect(() => {
    // 상호작용 이벤트 리스너 추가
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);
    window.addEventListener('scroll', handleUserActivity);

    return () => {
      // 이벤트 리스너 제거 및 타이머 정리
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
      window.removeEventListener('scroll', handleUserActivity);
      clearTimeout(userActivityTimeout);
    };
  });

  useEffect(() => {
    const sections = [robobrushRef, echoesRef, datavoucherRef];

    const intervalId = setInterval(() => {
      if (autoScrollEnabled && userInactive) {
        if (currentSectionIndex < sections.length) {
          const sectionRef = sections[currentSectionIndex]?.current;
          sectionRef?.scrollIntoView({ behavior: 'smooth' });
        } else {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }); // 최상단으로 이동
        }

        // 다음 섹션으로 인덱스 업데이트
        setCurrentSectionIndex((prevIndex) => 
          (prevIndex + 1) % (sections.length + 1)
        );
      }
    }, 6000);

    return () => clearInterval(intervalId);
  }, [autoScrollEnabled, userInactive, currentSectionIndex]);
  

  return (
    <div className='main'>
      <NavBar refs={{ robobrushRef, echoesRef, datavoucherRef }} activeSection={activeSection} />
      <div className="toggle-auto-scroll">
        <button className='autoscroll' onClick={() => setAutoScrollEnabled(!autoScrollEnabled)}>
          {autoScrollEnabled ? '자동스크롤 켜짐' : '자동스크롤 꺼짐'}
        </button>
      </div>
      <div ref={topPage}></div>
      <div className='main-logo'>        
        <img className='logobox' src={logobox} alt="logobox" />
        <img className='logoitem' src={logoitem} alt="logoitem" />
      <h3 className='main-slogan'>Imagine into Reality: 상상을 현실로</h3>
      </div>
      <div ref={robobrushRef}><Robobrush /></div>
      <div ref={echoesRef}><Echoes /></div>
      <div ref={datavoucherRef}><DataVoucher /></div>
    </div>
  );
};

export default Main;