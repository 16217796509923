import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y } from 'swiper';
import styled from 'styled-components';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import roboA from '../assets/images/roboA.png';
import roboB from '../assets/images/roboB.png';
import roboC from '../assets/images/roboC.png';
import roboD from '../assets/images/roboD.png';

const RoboSwiper = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const images = [roboA, roboB, roboC, roboD];

  useEffect(() => {
    // currentSlide가 변경될 때 추가 로직 사용 가능
  }, [currentSlide]);

  return (
    <SwiperContainer>
      <Swiper
        modules={[Navigation, Pagination, A11y]}
        spaceBetween={30}
        slidesPerView={1}
        loop={true}
        navigation
        pagination={{ clickable: true }}
        onSlideChange={(swiper) => setCurrentSlide(swiper.activeIndex)}
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <ImageWrapper>
              <img src={image} alt={`메인그림 ${index + 1}`} />
            </ImageWrapper>
          </SwiperSlide>
        ))}
      </Swiper>
    </SwiperContainer>
  );
};

export default RoboSwiper;

const SwiperContainer = styled.div`
  max-width: 400px;
  margin: auto;

  .swiper-button-prev,
  .swiper-button-next {
    color: #000;
    &::after {
      font-size: 25px;
    }
  }

  .swiper-pagination-bullet {
    background: #000;
  }

  .swiper-pagination-bullet-active {
    background: #000;
  }

  @media (max-width: 768px) {
    max-width: 250px;
  }
`;

const ImageWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    object-fit: cover;
  }
`;