import React from "react";
import "../styles/Footer.css"

function Footer() {
    return (
      <footer className="footer_container">
        <div className="footer_content">
          <p className="company">© IMAGINERY.Inc All rights reserved.</p>
          <p className="info">법인명(상호): 주식회사 이매지너리 | 사업자등록번호: 782-81-02642 | 통신판매업: 제 2023-서울마포-1651 호 | 주소: 서울특별시 마포구 월드컵북로98, 마이비즈니스파트너 | 대표자명: 신준호</p>
        </div>
      </footer>
    )
}

export default Footer;