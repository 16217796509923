import React, { useEffect, useRef, useState } from 'react'
import '../styles/Echoes.css'
import EchoMain from '../assets/images/Echosmain.png'
import EchoBtn from '../assets/images/echos_logo_btn.png'

const Robobrush = () => {

  const echoURL = "https://tumblbug.com/projectechoes?ref=%EB%A9%94%EC%9D%B8%2F%EC%B5%9C%EA%B7%BC"

  const [showButton, setShowButton] = useState(false);
  const buttonRef = useRef();
  const timerRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show');
          observer.unobserve(entry.target);
        }
      });
    }, {
      threshold: .2
    });

    const sections = document.querySelectorAll('.section-echo .fade-in');
    sections.forEach(section => {
      observer.observe(section);
    });
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // 화면에 보일 때 타이머 시작
          timerRef.current = setTimeout(() => {
            setShowButton(true);
          }, 2500);
        } else {
          // 화면에서 벗어나면 타이머 취소
          clearTimeout(timerRef.current);
          setShowButton(false);
        }
      });
    }, {
      threshold: 0.1
    });

    const target = buttonRef.current;
    if (target) {
      observer.observe(target);
    }

    return () => {
      if (target) {
        observer.unobserve(target);
      }
      clearTimeout(timerRef.current); // 컴포넌트가 언마운트 될 때 타이머 취소
    };
  }, []);

  return (
    <div className='section-echo' ref={buttonRef}>
      <div className='container'>
        <h2 className='section-sub fade-in'>자유로운 감정 대화를 위한 LLM 시뮬레이션</h2>
        <h1 className='section-title fade-in'>Project: ECHOES</h1>
        <div className='section-pic fade-in'>
          <img className={`echo-main ${showButton ? 'blur' : ''}`} src={EchoMain} alt='메인그림'/>
          <img className={`echo-btn ${showButton ? 'showbtn' : ''}`}
            onClick={()=>{window.open(echoURL)}} src={EchoBtn} alt='링크버튼'/>
        </div>
        <p className='fade-in'>
          대화 시뮬레이션 게임 형식을 차용한 현실감 있는 감정 대화 LLM 프로젝트 에코즈<br/>
          감정 대화에 특화된 언어 생성 모델을 활용하여 사용자와의 깊은 감정 교류를 이뤄내는 것을 목표로 합니다.
        </p>      
      </div>
    </div>
  );
}

export default Robobrush;