import React, { useEffect } from 'react'
import '../styles/Robobrush.css'
// import roboA from '../assets/images/roboA.png'
import RoboSwiper from './RoboSwiper';

const Robobrush = () => {

  const rblink = () => {
    const url = 'https://robobrush.ai/';
    window.open(url, '_blank');
  }

  useEffect(() => {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show');
          observer.unobserve(entry.target);
        }
      });
    }, {
      threshold: .2
    });

    const sections = document.querySelectorAll('.section-robo .fade-in');
    sections.forEach(section => {
      observer.observe(section);
    });
  }, []);


  return (
    <div className='section-robo'>
      <div className='container'>
        <h2 className='section-sub fade-in'>생성 AI 기반의 광고 배경 자동 제작 플랫폼</h2>
        <h1 className='section-title fade-in'>ROBOBRUSH</h1>

        <div className='section-pic fade-in'>
          <RoboSwiper />
        </div>

        <p className='fade-in'>로보브러시는 광고 제작을 쉽고 빠르게 할 수 있는 이미지 생성 AI 기반 웹 플랫폼으로,<br/>
        누구나 복잡한 디자인 작업 없이도 원하는 광고를 손쉽게 만들 수 있도록 도와줍니다.
        </p>
        <button className='link fade-in' onClick={rblink}>로보브러시 바로가기</button>
      </div>
    </div>
  )
}

export default Robobrush