import React, { useEffect } from 'react'
import '../styles/DataVoucher.css'
import LogoWT from '../assets/images/ImagineryLogoWT.png'

const Robobrush = () => {
  const dvlink = () => {
    const url = 'http://kdata.or.kr/datavoucher/is/selectPortalFileDetail.do?rcpnYear=2024&brno=7828102642&sprnSctrCd=P01014002&prdcId=&sprnDsncCd=P11014001';
    window.open(url, '_blank');
  }

  useEffect(() => {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show');
          observer.unobserve(entry.target);
        }
      });
    }, {
      threshold: 0.1
    });

    const sections = document.querySelectorAll('.section-datavou .fade-in');
    sections.forEach(section => {
      observer.observe(section);
    });
  }, []);


  return (
    <div className='section-datavou'>
      <div className='container'>
        <h2 className='section-sub fade-in'>2024 데이터 바우처 공급기업</h2>
        
        <div className='section-pic fade-in'><img src={LogoWT} alt='IMAGINERY.Inc'/></div>
        <p className='fade-in'>자사 생성형 AI플랫폼 개발 및 딥러닝 외주 개발 사업상의 노하우를 바탕으로<br/>
        고객 맞춤형 데이터 가공 및 전용 소프트웨어를 개발해드립니다.
        </p>
        <button className='link fade-in' onClick={dvlink}>데이터 바우처 페이지 바로가기</button>
      </div>
    </div>
  )
}

export default Robobrush