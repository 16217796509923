import React from 'react';
import './App.css';
import { HashRouter, Route, Routes } from 'react-router-dom';
import NavBar from './components/NavBar';
import Main from './pages/Main'
import Footer from './components/Footer'

function App() {
  return (
    <HashRouter basename=''>
      <NavBar />
      <div className="main-content">
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="*" element={<Main />} />
        </Routes>
      </div>
      <Footer />
    </HashRouter>
      );
    }
  
  export default App;
  